import { FC, useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { ImportInterface } from '../../interfaces/Import/importInterfase';
import { FormText } from '../FormFields/FormText';
import { Formik, FormikErrors, FormikProps } from 'formik';
import {
  formantPackageType,
  formantShippingMethod,
  handleNumberChange,
} from '../../utils/format';
import { PrimaryButton } from '../Atoms/Buttons';
import { FormSearch } from '../FormFields/FormSearch';
import { useAppSelector } from '../../store/hooks';
import {
  filterBusinessUnit,
  filterCity,
  filterLocation,
  filterPieces,
} from '../../utils/filters';
import {
  BusinessUnitOption,
  CityOption,
  PiecesOption,
} from '../FormFields/FormSearchOptions';
import { FormSelect } from '../FormFields/FormSelect';
import {
  BusinessUnitInterface,
  DeliveryEnum,
  PieceInterface,
  ShipmentInterface,
  ShipmentStatusEnum,
  ShippingMethodEnum,
  TrackingEnum,
} from '../../interfaces';
import { FormDatePicker } from '../FormFields/FormDatePicker';
import moment from 'moment';
import { FormRadioGroup } from '../FormFields/FormRadioGroup';
import { PackageTypeEnum } from '../../interfaces/Shipment/PackageTypeEnum';
import { PaginationFooter } from '../Molecules/PaginationFooter';
import _ from 'lodash';
import * as Yup from 'yup';
import {
  createShipmentMasiveApi,
  getBusinessUnitsType,
  getShipmentsExists,
} from '../../services';
import {
  DocumentArrowDownIcon,
  BellAlertIcon,
} from '@heroicons/react/24/outline';
import ModalLoading from '../Atoms/ModalLoading';
import { ConfirmModalStatus } from '../Preshipment/ModalSuccess';
import { Nullable } from '../../interfaces/GenericTypes';

interface ImportListValues {
  importList: ImportInterface[];
}

interface ImportElementProps {
  formik: FormikProps<ImportListValues>;
  index: number;
}

const ImportElement: FC<ImportElementProps> = ({ formik, index }) => {
  const importitems = useMemo(() => {
    return formik.values.importList[index];
  }, [formik.values.importList, index]);

  const errors = useMemo(() => {
    return formik.errors?.importList?.[index] as FormikErrors<ImportInterface>;
  }, [formik.errors?.importList, index]);

  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );

  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const citiesType = useAppSelector((state) => state.inmutable.cities);
  const citiesByBuType = useAppSelector((state) => state.inmutable.citiesByBu);

  const typeShipment = [
    { value: DeliveryEnum.OFFICE, name: 'Oficina' },
    { value: DeliveryEnum.DELIVERY, name: 'Domicilio' },
  ];
  const pieceCategories = useAppSelector(
    (state) => state.inmutable.PieceCategories
  );

  useEffect(() => {
    let typeSend = formik.values.importList[index].C;
    if (typeSend == '20') {
      let city = citiesByBuType.find(
        (x) => x.locationCode === formik.values.importList[index].D
      );

      let buCode =
        businessUnits.filter((x) => x.buCode === city?.buCode)[0]?.buCode ?? '';

      formik.setFieldValue(`importList[${index}].E`, buCode);
    }
  }, [formik.values.importList[index].C]);

  const handleDateChange = (e: any, formik: any, index: any) => {
    const BValue = formik.values.importList[index].B;

    formik.values.importList?.forEach((item: ImportInterface, i: number) => {
      if (item.B == BValue) {
        formik.setFieldValue(
          `importList[${i}].A`,
          moment(e?.startDate).format('YYYY-MM-DD')
        );
      }
    });
  };

  const handleChange = (e: any, formik: any, letter: any, index: any) => {
    const BValue = formik.values.importList[index].B;
    formik.values.importList?.forEach((item: ImportInterface, i: number) => {
      if (item.B == BValue) {
        if (letter == 'C') {
          formik.setFieldValue(
            `importList[${i}].C`,
            typeShipment.find((x) => x.name == e)!.value
          );
        }
        // else if (letter == "D") {
        //   formik.setFieldValue(
        //     `importList[${index}].D`,
        //     citiesType.find((x) => x.locationCode == e?.locationCode)!
        //       .locationCode
        //   );
        //   formik.setFieldValue(
        //     `importList[${index}].E`,
        //     businessUnits.filter(
        //       (x) => x.location.locationCode == e?.locationCode
        //     ).length == 0
        //       ? citiesByBuType.find(
        //           (x) => x.locationCode == e?.locationCode
        //         ) != undefined
        //         ? businessUnits.filter(
        //             (x) =>
        //               x.location.code ==
        //                 citiesByBuType.find(
        //                   (x) => x.locationCode == e?.locationCode
        //                 )?.buCode ?? ""
        //           )[0].buCode
        //         : ""
        //       : businessUnits.filter(
        //           (x) => x.location.locationCode == e?.locationCode
        //         ).length == 1
        //       ? businessUnits.filter(
        //           (x) => x.location.locationCode == e?.locationCode
        //         )[0].buCode
        //       : ""
        //   );
        // }
        else formik.setFieldValue(`importList[${i}].${letter}`, e.value);
      }
    });
  };

  return (
    <>
      <tr
        className={classNames(
          !formik.errors?.importList?.[index]
            ? 'hover:bg-gray-200'
            : 'hover:bg-red-200',
          formik.errors?.importList?.[index]
            ? 'bg-red-100 '
            : index % 2 == 0 && 'bg-gray-100',
          ''
        )}
      >
        <td className="px-4 py-1 font-semibold sticky left-0 z-10 bg-white ">
          <div
          // style={{
          //   display: formik.values.importList[index].disabled
          //     ? "None"
          //     : "Block",
          // }}
          >
            <FormDatePicker
              id={`importList[${index}].A`}
              name={`importList[${index}].A`}
              label=""
              value={{
                startDate: formik.values.importList[index].A ?? null,
                endDate: formik.values.importList[index].A ?? null,
              }}
              className="!h-8"
              toggleClassName={(oldClassname) =>
                classNames(oldClassname, 'text-main-500')
              }
              useRange={false}
              //onChange={(e) => handleDateChange(e, formik, index)}
              onChange={formik.handleChange}
              configs={{
                shortcuts: {
                  today: 'Hoy',
                  yesterday: 'Ayer',
                  currentMonth: 'Mes actual',
                  pastMonth: 'Mes anterior',
                  past: (period: number) => `Hace ${period} días`,
                },
              }}
            />
          </div>
        </td>
        <td className="px-4 py-1 font-semibold text-center sticky left-40  z-10 bg-white">
          <FormText
            id={`importList[${index}].B`}
            name={`importList[${index}].B`}
            label=""
            value={formik.values.importList[index].B}
            error={
              formik.touched?.importList?.[index]?.B && errors?.B
                ? errors?.B
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            // disabled={true}
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormSelect
            id={`importList[${index}].C`}
            name={`importList[${index}].C`}
            label=""
            selected={
              typeShipment.find(
                (x) =>
                  x.value.toString().toUpperCase() ===
                    formik.values.importList[
                      index
                    ].C.toString().toUpperCase() ||
                  x.name.toUpperCase() ===
                    formik.values.importList[index].C.toString().toUpperCase()
              )?.name
            }
            widthClassName="!max-h-20"
            className="!h-8"
            options={typeShipment.map((x) => x.name)}
            error={
              formik.touched?.importList?.[index]?.C && errors?.C
                ? errors?.C
                : undefined
            }
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            optionString={(option) => option}
            onSelectOption={(option) =>
              formik.setFieldValue(
                `importList[${index}].C`,
                typeShipment.find((x) => x.name === option)!.value
              )
            }
            isWidth={true}
            // style={{
            //   display: formik.values.importList[index].disabled
            //     ? "None"
            //     : "Block",
            // }}
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormSearch
            id={`importList[${index}].D`}
            name={`importList[${index}].D`}
            label=""
            placeholder="Ingresa la tienda"
            value={
              citiesType.find(
                (x) =>
                  x.locationCode ===
                  formik.values.importList[index]?.D?.toString()
              )?.locationName ?? formik.values.importList[index]?.D?.toString()
            }
            options={citiesType}
            unselect={
              !!(formik.values.importList[index].D.toString().length > 0
                ? ''
                : undefined)
            }
            className="!h-8"
            widthClassName="!max-h-20"
            error={
              formik.touched?.importList?.[index]?.D && errors?.D
                ? errors?.D
                : undefined
            }
            onBlur={formik.handleBlur}
            onChange={(e) => {
              //handleChange(e.target.value, formik, "D", index);
              formik.setFieldValue(`importList[${index}].D`, e.target.value);
            }}
            onSelectOption={(option) => {
              formik.setFieldValue(
                `importList[${index}].D`,
                citiesType.find((x) => x.locationCode === option?.locationCode)!
                  .locationCode
              );
              formik.setFieldValue(
                `importList[${index}].E`,
                businessUnits.filter(
                  (x) => x.location.locationCode === option?.locationCode
                ).length === 0
                  ? citiesByBuType.find(
                      (x) => x.locationCode === option?.locationCode
                    ) !== undefined
                    ? businessUnits.filter(
                        (x) =>
                          x.location.code ===
                            citiesByBuType.find(
                              (x) => x.locationCode === option?.locationCode
                            )?.buCode ?? ''
                      )[0].buCode
                    : ''
                  : businessUnits.filter(
                      (x) => x.location.locationCode === option?.locationCode
                    ).length === 1
                  ? businessUnits.filter(
                      (x) => x.location.locationCode === option?.locationCode
                    )[0].buCode
                  : ''
              );
            }}
            onFilter={filterCity}
            isTabled={true}
            RenderOption={({ option }) => <CityOption {...option} />}
            // style={{
            //   display: formik.values.importList[index].disabled
            //     ? "None"
            //     : "Block",
            // }}
          />
        </td>
        <td className="px-4 py-1 font-semibold">
          <FormSearch
            id={`importList[${index}].E`}
            name={`importList[${index}].E`}
            label=""
            placeholder="Ingresa la tienda"
            value={formik.values.importList[index].E.toString()}
            options={
              businessUnits.filter(
                (x) =>
                  x.location.locationCode ===
                  formik.values.importList[index].D.toString()
              ).length === 0
                ? businessUnits.filter(
                    (x) =>
                      x.location.code ===
                      citiesByBuType.find(
                        (x) =>
                          x.locationCode ===
                          formik.values.importList[index].D.toString()
                      )?.buCode
                  )
                : businessUnits
            }
            unselect={
              !!(formik.values.importList[index].E.toString().length > 0
                ? ''
                : undefined)
            }
            className="!h-8"
            widthClassName="!max-h-20"
            error={
              formik.touched?.importList?.[index]?.E && errors?.E
                ? errors?.E
                : undefined
            }
            disabled={formik.values.importList[index].C == '20' ? true : false}
            onBlur={formik.handleBlur}
            onChange={(e) =>
              formik.setFieldValue(`importList[${index}].E`, e.target.value)
            }
            onSelectOption={(option) =>
              formik.setFieldValue(`importList[${index}].E`, option?.buCode)
            }
            onFilter={filterBusinessUnit}
            isTabled={true}
            RenderOption={({ option }) => <BusinessUnitOption {...option} />}
            // style={{
            //   display: formik.values.importList[index].disabled
            //     ? "None"
            //     : "Block",
            // }}
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormSelect
            id={`importList[${index}].F`}
            name={`importList[${index}].F`}
            label=""
            selected={
              identificationTypes.find(
                (x) =>
                  x.taxIdentificationTypeId.toString() ===
                    formik.values.importList[index].F.toString() ||
                  x.abbreviationName.slice(0, 1) ===
                    formik.values.importList[index].F.toString()
              )?.abbreviationName
            }
            options={identificationTypes.map((type) => type.abbreviationName)}
            error={
              formik.touched?.importList?.[index]?.F && errors?.F
                ? errors?.F
                : undefined
            }
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            className="!h-8"
            optionString={(option) => option.slice(0, 1)}
            onSelectOption={(option) =>
              formik.setFieldValue(
                `importList[${index}].F`,
                identificationTypes.find((x) => x.abbreviationName === option)!
                  .taxIdentificationTypeId
              )
            }
            isWidth={true}
            // style={{
            //   display: formik.values.importList[index].disabled
            //     ? "None"
            //     : "Block",
            // }}
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].G`}
            name={`importList[${index}].G`}
            label=""
            value={formik.values.importList[index].G}
            error={
              formik.touched?.importList?.[index]?.G && errors?.G
                ? errors?.G
                : undefined
            }
            onChange={(e) => handleNumberChange(e, formik, false)}
            onBlur={formik.handleBlur}
            className="!h-8"
            // style={{
            //   display: formik.values.importList[index].disabled
            //     ? "None"
            //     : "Block",
            // }}
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].H`}
            name={`importList[${index}].H`}
            label=""
            value={formik.values.importList[index].H}
            error={
              formik.touched?.importList?.[index]?.H && errors?.H
                ? errors?.H
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            // style={{
            //   display: formik.values.importList[index].disabled
            //     ? "None"
            //     : "Block",
            // }}
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormSelect
            id={`importList[${index}].I`}
            name={`importList[${index}].I`}
            label=""
            selected={
              identificationTypes.find(
                (x) =>
                  x.taxIdentificationTypeId.toString() ===
                    formik.values.importList[index].I.toString() ||
                  x.abbreviationName.slice(0, 1) ===
                    formik.values.importList[index].I.toString()
              )?.abbreviationName
            }
            options={identificationTypes.map((type) => type.abbreviationName)}
            error={
              formik.touched?.importList?.[index]?.I && errors?.I
                ? errors?.I
                : undefined
            }
            isWidth={true}
            onBlur={formik.handleBlur}
            labelClassname="text-md"
            optionString={(option) => option.slice(0, 1)}
            onSelectOption={(option) =>
              formik.setFieldValue(
                `importList[${index}].I`,
                identificationTypes.find((x) => x.abbreviationName === option)!
                  .taxIdentificationTypeId
              )
            }
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].I`}
            name={`importList[${index}].J`}
            label=""
            value={formik.values.importList[index].J}
            error={
              formik.touched?.importList?.[index]?.J && errors?.J
                ? errors?.J
                : undefined
            }
            onChange={(e) => handleNumberChange(e, formik, false)}
            onBlur={formik.handleBlur}
            className="!h-8"
            // style={{
            //   display: formik.values.importList[index].disabled
            //     ? "None"
            //     : "Block",
            // }}
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].K`}
            name={`importList[${index}].K`}
            label=""
            value={formik.values.importList[index].K}
            error={
              formik.touched?.importList?.[index]?.K && errors?.K
                ? errors?.K
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].L`}
            name={`importList[${index}].L`}
            label=""
            value={formik.values.importList[index].L}
            error={
              formik.touched?.importList?.[index]?.L && errors?.L
                ? errors?.L
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              re = /^[0-9\b]{0,10}$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            className="!h-8"
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].M`}
            name={`importList[${index}].M`}
            label=""
            value={formik.values.importList[index].M}
            error={
              formik.touched?.importList?.[index]?.M && errors?.M
                ? errors?.M
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            // style={{
            //   display: formik.values.importList[index].disabled
            //     ? "None"
            //     : "Block",
            // }}
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].N`}
            name={`importList[${index}].N`}
            label=""
            value={formik.values.importList[index].N}
            error={
              formik.touched?.importList?.[index]?.N && errors?.N
                ? errors?.N
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
            // style={{
            //   display: formik.values.importList[index].disabled
            //     ? "None"
            //     : "Block",
            // }}
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].O`}
            name={`importList[${index}].O`}
            label=""
            value={formik.values.importList[index].O}
            error={
              formik.touched?.importList?.[index]?.O && errors?.O
                ? errors?.O
                : undefined
            }
            onChange={(e) => {
              let re: RegExp;
              re = /^[0-9\b]{0,6}$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            className="!h-8"
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].P`}
            name={`importList[${index}].P`}
            label=""
            value={formik.values.importList[index].P}
            error={
              formik.touched?.importList?.[index]?.P && errors?.P
                ? errors?.P
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].Q`}
            name={`importList[${index}].Q`}
            label=""
            value={formik.values.importList[index].Q}
            error={
              formik.touched?.importList?.[index]?.Q && errors?.Q
                ? errors?.Q
                : undefined
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="!h-8"
          />
        </td>
        <td className="px-4 py-1 font-semibold">
          <FormSearch
            id={`importList[${index}].W`}
            name={`importList[${index}].W`}
            label=""
            placeholder="Ingresa la piezas"
            value={
              pieceCategories.find(
                (x) => x.id === formik.values.importList[index].W.toString()
              )?.name ?? ''
            }
            options={pieceCategories}
            unselect={
              !!(formik.values.importList[index].W.toString().length > 0
                ? ''
                : undefined)
            }
            className="!h-8"
            widthClassName="!max-h-20"
            error={
              formik.touched?.importList?.[index]?.W && errors?.W
                ? errors?.W
                : undefined
            }
            onBlur={formik.handleBlur}
            onChange={(e) =>
              formik.setFieldValue(`importList[${index}].W`, e.target.value)
            }
            onSelectOption={(option) => {
              formik.setFieldValue(`importList[${index}].W`, option?.id);
            }}
            isTabled={true}
            onFilter={filterPieces}
            RenderOption={({ option }) => <PiecesOption {...option} />}
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].R`}
            name={`importList[${index}].R`}
            label=""
            value={formik.values.importList[index].R}
            error={
              formik.touched?.importList?.[index]?.R && errors?.R
                ? errors?.R
                : undefined
            }
            onChange={(e) => handleNumberChange(e, formik, false)}
            onBlur={formik.handleBlur}
            className="!h-8"
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].S`}
            name={`importList[${index}].S`}
            label=""
            value={formik.values.importList[index].S}
            error={
              formik.touched?.importList?.[index]?.S && errors?.S
                ? errors?.S
                : undefined
            }
            onChange={(e) => handleNumberChange(e, formik, true)}
            onBlur={formik.handleBlur}
            className="!h-8"
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].T`}
            name={`importList[${index}].T`}
            label=""
            value={formik.values.importList[index].T}
            error={
              formik.touched?.importList?.[index]?.T && errors?.T
                ? errors?.T
                : undefined
            }
            onChange={(e) => handleNumberChange(e, formik, true)}
            onBlur={formik.handleBlur}
            className="!h-8"
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].U`}
            name={`importList[${index}].U`}
            label=""
            value={formik.values.importList[index].U}
            error={
              formik.touched?.importList?.[index]?.U && errors?.U
                ? errors?.U
                : undefined
            }
            onChange={(e) => handleNumberChange(e, formik, true)}
            onBlur={formik.handleBlur}
            className="!h-8"
          />
        </td>
        <td className="px-4 py-1 font-semibold text-center">
          <FormText
            id={`importList[${index}].V`}
            name={`importList[${index}].V`}
            label=""
            value={formik.values.importList[index].V}
            error={
              formik.touched?.importList?.[index]?.V && errors?.V
                ? errors?.V
                : undefined
            }
            onChange={(e) => handleNumberChange(e, formik, true)}
            onBlur={formik.handleBlur}
            className="!h-8"
          />
        </td>
      </tr>
      {((formik.touched?.importList?.[index]?.S && errors?.S
        ? errors?.S
        : undefined) != undefined ||
        (formik.touched?.importList?.[index]?.R && errors?.R
          ? errors?.R
          : undefined) != undefined) && (
        <tr
          className={classNames(
            !formik.errors?.importList?.[index]
              ? 'hover:bg-gray-200'
              : 'hover:bg-red-200',
            formik.errors?.importList?.[index]
              ? 'bg-red-100 '
              : index % 2 == 0 && 'bg-gray-100',
            ''
          )}
        >
          <td
            className="px-4 py-1 font-semibold text-center sticky left-40  z-10 bg-white"
            colSpan={19}
          ></td>
          <td
            className="px-4 py-1 font-semibold text-center sticky left-40  z-10 bg-white"
            colSpan={3}
          >
            <div className="flex h-full flex-col gap-12">
              <p className="text-xs text-red-500">
                Las medidas son inválidas, por favor revíselas y corríjalas
              </p>
            </div>
          </td>
          <td className="px-4 py-1 font-semibold text-center"></td>
        </tr>
      )}
    </>
  );
};

interface ImportsMasiveTableProps {
  imports: ImportInterface[];
  setNext: React.Dispatch<React.SetStateAction<boolean>>;
  guideExits: string;
  setGuideExits: React.Dispatch<React.SetStateAction<string>>;
}

export const ImportsMasiveTable: FC<ImportsMasiveTableProps> = ({
  imports,
  setNext,
  guideExits,
  setGuideExits,
}) => {
  async function fetchBusinessUnits() {
    try {
      const businessUnitsGet = await getBusinessUnitsType(40);
      setBuPlatform(businessUnitsGet as BusinessUnitInterface[]);

      console.log(businessUnitsGet);
    } catch (error) {
      console.error('Error fetching business units:', error);
    }
  }

  useEffect(() => {
    fetchBusinessUnits();
  }, []);

  const [shippingMethod, setShippingMethod] = useState<ShippingMethodEnum>(
    ShippingMethodEnum.LAND
  );
  const [packageType, setPackageType] = useState<PackageTypeEnum>(
    PackageTypeEnum.BOX
  );
  const [page, setPage] = useState(0);
  const [registersNumber, setRegistersNumber] = useState(10);
  const [search, setSearch] = useState('');
  const [buPlatform, setBuPlatform] = useState([] as BusinessUnitInterface[]);

  const filteredShipments = useMemo(() => {
    setPage(0);
    const tokens = search?.toLowerCase().split(' ');

    return imports.filter((shipment) => {
      return tokens.every((token) => {
        return (
          shipment.A?.toString().toLowerCase().includes(token) ||
          shipment.B?.toString().toLowerCase().includes(token) ||
          shipment.F?.toString().toLowerCase().includes(token) ||
          shipment.G?.toString().toLowerCase().includes(token) ||
          shipment.H?.toString().toLowerCase().includes(token) ||
          shipment.K?.toString().toLowerCase().includes(token)
        );
      });
    });
  }, [imports, search]);
  const paginatedShipments = useMemo(() => {
    const orderedShipments = filteredShipments.sort((a, b) => {
      return a.B.localeCompare(b.B);
    });
    const start = page * registersNumber;
    const end = start + registersNumber;
    return orderedShipments.slice(start, end);
  }, [filteredShipments, page, registersNumber]);
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const pieceCategories = useAppSelector(
    (state) => state.inmutable.PieceCategories
  );
  const user = useAppSelector((state) => state.user)!;
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const citiesType = useAppSelector((state) => state.inmutable.cities);

  const typeShipment = [
    { value: DeliveryEnum.OFFICE, name: 'Oficina' },
    { value: DeliveryEnum.DELIVERY, name: 'Domicilio' },
  ];

  const parameterDensityMax = useAppSelector(
    (state) => state.inmutable.densityMax
  );

  const parameterVolumeMin = useAppSelector(
    (state) => state.inmutable.volumenMin
  );

  const citiesByBuType = useAppSelector((state) => state.inmutable.citiesByBu);

  const validationSchema = Yup.object().shape({
    importList: Yup.array<ImportInterface>().of(
      Yup.object<ImportInterface>().shape({
        A: Yup.string().required('Campo requerido'),
        B: Yup.string()
          .max(50, 'maximo 50 caracteres')
          .required('Campo requerido')
          .test({
            name: 'exitsNumber',
            message: 'Existe el Nº Guia',
            test: (code) => {
              return !guideExits.includes(code);
            },
          }),
        C: Yup.string()
          .required('Campo requerido')
          .test({
            name: 'typeShipment',
            message: 'No existe el tipo de retiro',
            test: (code) => typeShipment.some((b) => b.value == code),
          }),
        D: Yup.string()
          .required('Campo requerido')
          .test({
            name: 'cityShipment',
            message: 'No existe la ciudad',
            test: (code) => citiesType.some((b) => b.locationCode == code),
          }),
        E: Yup.string()
          .test({
            name: 'businessUnit',
            message: 'No existe la tienda retiro',
            test: (code) => {
              console.log(businessUnits);
              if (code != undefined)
                return businessUnits.some((b) => b.buCode == code);
              else return true;
            },
          })
          .test({
            name: 'businessUnitTypeSend',
            message: 'Seleccione una oficina valida',
            test: (code, { parent }) => {
              if (parent.C.toString() == '10') {
                let isPlatform = buPlatform.some((x) => x.buCode === code);
                console.log(isPlatform);
                if (isPlatform) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            },
          })
          .test({
            name: 'businessUnitTypeRequire',
            message: 'Campo requerido',
            test: (code, { parent }) => {
              if (parent.C.toString() == '10') {
                if (code === undefined || code === '') {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            },
          }),
        F: Yup.string()
          .required('Campo requerido')
          .test({
            name: 'identificationType',
            message: 'No existe',
            test: (code) =>
              identificationTypes.some(
                (b) => b.taxIdentificationTypeId.toString() == code
              ),
          }),
        G: Yup.number()
          .required('Campo requerido')
          .test(
            'len',
            'Maximo 50 caracteres',
            (val) => val.toString().length <= 50
          )
          .typeError('Ingrese solo numeros'),
        H: Yup.string()
          .required('Campo requerido')
          .max(100, 'maximo 100 caracteres'),
        I: Yup.string()
          .required('Campo requerido')
          .test({
            name: 'identificationType',
            message: 'No existe',
            test: (code) =>
              identificationTypes.some(
                (b) => b.taxIdentificationTypeId.toString() == code
              ),
          }),
        J: Yup.number()
          .required('Campo requerido')
          .test(
            'len',
            'Maximo 50 caracteres',
            (val) => val.toString().length <= 50
          )
          .typeError('Ingrese solo numeros'),
        K: Yup.string()
          .max(50, 'maximo 50 caracteres')
          .required('Campo requerido'),
        L: Yup.number()
          .required('Campo requerido')
          .typeError('Ingrese solo numeros')
          .test(
            'lenMin',
            'Minimo 5 caracteres',
            (val) => val.toString().length >= 5
          ),
        M: Yup.string()
          .email('El Formato es invalido')
          .max(500, 'maximo 500 caracteres'),
        N: Yup.string()
          .max(500, 'maximo 500 caracteres')
          .required('Campo requerido'),
        O: Yup.string().max(10, 'maximo 10 caracteres'),
        P: Yup.string().max(150, 'maximo 150 caracteres'),
        Q: Yup.number()
          .required('Campo requerido')
          .typeError('Ingrese solo numeros')
          .min(1, 'Minimo de caracteres 1'),
        R: Yup.number()
          .required('Campo requerido')
          .typeError('ingrese solo numeros')
          .test({
            name: 'kgMax',
            message: 'Debe ser menor a 999kg',
            test: (code) => {
              if (PackageTypeEnum.ABOUT == packageType) return true;
              else return code < 1000;
            },
          })
          .test({
            name: 'kgMax',
            message: 'Debe ser menor a 0.500kg',
            test: (code) => {
              if (PackageTypeEnum.BOX == packageType) return true;
              else return code < 0.501;
            },
          })
          .test('parameterDensityMax', ' ', (value, object) => {
            if (!value) return false;
            if (packageType === PackageTypeEnum.ABOUT) return true;
            var volumen =
              Number(object.parent.S ?? 0) *
              Number(object.parent.T ?? 0) *
              Number(object.parent.U ?? 0);
            var total = Number(object.parent.R ?? 0) / volumen;
            return total < Number(parameterDensityMax[0].valueGeneral);
          }),
        S: Yup.number()
          .required('Campo requerido')
          .test('volumenDimensity', ' ', (value, config) => {
            if (!value) return false;
            var volumenTotal =
              Number(config.parent.S ?? 0) *
              Number(config.parent.T ?? 0) *
              Number(config.parent.U ?? 0);

            let volumen = parameterVolumeMin[0].valueGeneral;
            return volumenTotal > Number(volumen);
          })
          .typeError('ingrese solo numeros'),
        T: Yup.number()
          .test('volumenDimensity', ' ', (value, config) => {
            if (!value) return false;
            var volumenTotal =
              Number(config.parent.S ?? 0) *
              Number(config.parent.T ?? 0) *
              Number(config.parent.U ?? 0);

            let volumen = parameterVolumeMin[0].valueGeneral;
            return volumenTotal > Number(volumen);
          })
          .required('Campo requerido')
          .typeError('ingrese solo numeros'),
        U: Yup.number()
          .test('volumenDimensity', ' ', (value, config) => {
            if (!value) return false;

            var volumenTotal =
              Number(config.parent.S ?? 0) *
              Number(config.parent.T ?? 0) *
              Number(config.parent.U ?? 0);

            let volumen = parameterVolumeMin[0].valueGeneral;
            return volumenTotal > Number(volumen);
          })
          .required('Campo requerido')
          .typeError('ingrese solo numeros'),
        V: Yup.number()
          .required('Campo requerido')
          .typeError('ingrese solo numeros'),
        W: Yup.string().required('Campo requerido'),
      })
    ),
  });

  let [loading, setLoading] = useState(false);
  let [disabled, setDisabled] = useState(false);

  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  const splitString = (str: string) => {
    let arr = str.split(',');
    let result = [];
    let temp = '';
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
      temp += arr[i];
      count += arr[i].length;
      if (count >= 200) {
        result.push(temp);
        temp = '';
        count = 0;
      } else {
        temp += ',';
        count++;
      }
    }
    if (temp) {
      result.push(temp);
    }
    return result;
  };

  return (
    <div className="relative w-full table-fix-head mt-4 ">
      <Formik
        initialValues={{ importList: imports }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setLoadingStatus(ConfirmModalStatus.PROCESSING);
          setLoadingTitle('Guardando...');
          setLoading(true);
          setDisabled(true);

          let groupeds = values.importList.reduce(
            (result: any, currentValue: any) => {
              (result[currentValue['B']] =
                result[currentValue['B']] || []).push(currentValue);
              return result;
            },
            {}
          );

          const columnB = values.importList.map(function (value) {
            return value['B'];
          });

          const concatenatedColumnB = columnB.join(',');
          let dataExistArray = splitString(concatenatedColumnB);
          let dataExits = '';
          for (let i = 0; i < dataExistArray.length; i++) {
            dataExits += await getShipmentsExists(dataExistArray[i]);
          }
          setGuideExits(dataExits);
          if (dataExits.length > 0) {
            setDisabled(false);
            setLoadingTitle(
              `Las guias (${dataExits}) se encuentran registrada por favor modificalas`
            );
            setLoadingStatus(ConfirmModalStatus.ERROR);
            return;
          }

          let accountBillTo = user.paymentMethod?.filter(
            (x) => x.paymentModeID.toString() === '3'
          )![0]?.accountBillTo;

          const preShipment: ShipmentInterface[] = [];
          _.each(
            _.sortBy(_.toArray(groupeds), function (num) {
              return num;
            }).reverse(),
            function (items) {
              let dataDes = items[0] as ImportInterface;

              let piecesData: PieceInterface[] = [];
              items.forEach((itemdata: ImportInterface, indexs: string) => {
                for (let i = 0; i < parseInt(itemdata.Q); i++) {
                  piecesData.push({
                    id: '' + indexs + 1,
                    category: pieceCategories.find(
                      (x) =>
                        x.id.toString().trim() === itemdata.W.toString().trim()
                    )!,
                    height: parseFloat(itemdata.S),
                    width: parseFloat(itemdata.T),
                    length: parseFloat(itemdata.U),
                    weight: parseFloat(itemdata.R),
                    declaredValue: parseFloat(itemdata.V ?? '0'),
                    packageType: packageType,
                  });
                }
              });
              let buCodeConsigne = businessUnits.find(
                (x) =>
                  x.buCode.toString().toUpperCase().trim() ===
                  dataDes.E.toString()?.toUpperCase()?.trim()
              );

              if (buCodeConsigne == undefined) {
                buCodeConsigne = businessUnits.filter(
                  (x) =>
                    x.location.code ===
                    citiesByBuType.find(
                      (x) => x.locationCode === dataDes.D.toString()
                    )?.buCode
                )![0];
              }

              preShipment.push({
                id: '',
                number: 0,
                trackingDetails: dataDes?.B?.toString(),
                pieces: piecesData,
                observations: dataDes?.P?.toString(),
                totalPieces: piecesData.length + 1,
                totalWeight: piecesData.reduce(
                  (acc, cur) => acc + Number(cur.weight),
                  0
                ),
                declaredValue: piecesData.reduce(
                  (acc, cur) => acc + Number(cur.declaredValue),
                  0
                ),
                tracking: TrackingEnum.TRACKING,
                isHighValueCargo: false,
                service: '60',
                deliveryType: dataDes?.C as DeliveryEnum,
                shippingMethod: shippingMethod,
                creationUser: user.user?.login ?? 'ccw',
                shipper: user.client!,
                businessUnitOrigin: user.client!.businessUnit!,
                businessUnitConsignee: buCodeConsigne,
                sender: {
                  fullName: dataDes.H,
                  abbreviationId: identificationTypes.find((t) =>
                    t.taxIdentificationTypeId.toString() === dataDes.F
                      ? t.taxIdentificationTypeId.toString()
                      : 'V-'
                  )?.taxIdentificationTypeId,
                  abbreviationName: identificationTypes.find((t) =>
                    t.taxIdentificationTypeId.toString() === dataDes.F
                      ? t.taxIdentificationTypeId.toString()
                      : 'V-'
                  )?.abbreviationName,
                  identificationNumber: dataDes.G,
                },
                consignee: {
                  id: '',
                  accountCode: '',
                  agreementId: 0,
                  cityCode: dataDes.D.toString().toUpperCase().trim(),
                  taxIdentificationTypeID: identificationTypes.find((t) =>
                    t.taxIdentificationTypeId.toString() === dataDes.I
                      ? t.taxIdentificationTypeId.toString()
                      : 'V-'
                  )?.taxIdentificationTypeId,
                  abbreviationName:
                    identificationTypes.find((t) =>
                      t.taxIdentificationTypeId.toString() === dataDes.I
                        ? t.taxIdentificationTypeId.toString()
                        : 'V-'
                    )?.abbreviationName ?? 'V-',
                  identificationNumber: dataDes.J?.toString(),
                  accountFullName: dataDes.K?.toString(),
                  fiscalAddress: dataDes.N?.toString(),
                  listAccountPhone: [
                    {
                      countryId: '236',
                      acceptsSMS: false,
                      countryPhoneAccessCode: '+58',
                      phoneTypeId: '1',
                      phoneNumber: dataDes.L?.toString(),
                    },
                  ],
                  listAccountEmail: [
                    {
                      id: '',
                      emailTypeId: '2',
                      email: dataDes.M?.toString(),
                    },
                  ],
                  listAuthorizingAccount: [],
                },
                shippingLocation: {
                  name: dataDes.D.toString().toUpperCase().trim(),
                  address: dataDes.N?.toString(),
                  postalCode: dataDes.O?.toString(),
                  coordinates: buCodeConsigne!.location.coordinates,
                },
                isSED: false,
                isToHold: false,
                isFragile: false,
                isRepacke: false,
                isPreAlerted: false,
                isSafeKeeping: false,
                status: ShipmentStatusEnum.ACTIVE,
                packageType: packageType,
                accountBillToID: accountBillTo,
                externalManfiest: dataDes.externalManifiest
                  ?.toString()
                  .toUpperCase()
                  .trim(),
              });
            }
          );

          const newShipment = await createShipmentMasiveApi(preShipment);
          if (!newShipment.didError) {
            var guideNumber = newShipment.model
              ?.map((objeto) => objeto.shipmentNumber)
              .join(', ');
            setLoading(true);
            setLoadingTitle(`Guias generadas con exito: ${guideNumber}`);
            setLoadingStatus(ConfirmModalStatus.SUCCESS);
            setTimeout(() => {
              setDisabled(false);
              setNext(false);
            }, 3000);
          } else {
            setDisabled(false);
            setLoadingTitle('' + newShipment.errorMessage);
            setLoadingStatus(ConfirmModalStatus.ERROR);
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-5">
              {(formik.errors.importList?.length ?? 0) > 0 && (
                <div
                  className="flex border-t-2 border-red-500 bg-red-100 text-red-700 px-4 py-3"
                  role="alert"
                >
                  <BellAlertIcon className="fill-current w-4 h-4 mr-2 mt-1"></BellAlertIcon>
                  <p>
                    Existen campos con error! Los mismo se encuentran en rojo.
                    Valide, corriga e intente de nuevo.
                  </p>
                </div>
              )}
            </div>

            <div className="flex flex-2 flex-col-reverse md:flex-row">
              <div className="flex flex-1" style={{ maxWidth: '30rem' }}>
                <div className="mt-2 mb-8">
                  <FormRadioGroup
                    id="shippingMethod"
                    name="shippingMethod"
                    label="Método de envío"
                    selected={shippingMethod}
                    labelClassname="!text-xs flex sm:!justify-end sm:w-32"
                    className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                    wrapperClassName="flex !flex-row"
                    optionsClassName="text-xs !ml-1.5"
                    options={Object.values(ShippingMethodEnum).map(
                      (method) => ({
                        name: formantShippingMethod(method),
                        value: method,
                      })
                    )}
                    onSelectOption={(opt) => setShippingMethod(opt)}
                  />
                </div>
              </div>
              <div className="flex flex-1" style={{ maxWidth: '30rem' }}>
                <div className="mt-2 mb-8">
                  <FormRadioGroup
                    id="packageType"
                    name="packageType"
                    label="Tipo de paquete"
                    selected={packageType}
                    labelClassname="!text-xs flex sm:!justify-end sm:w-32"
                    className="flex flex-1 sm:ml-0 sm:gap-8 sm:items-center flex-col sm:flex-row"
                    wrapperClassName="flex !flex-row"
                    optionsClassName="text-xs !ml-1.5"
                    options={Object.values(PackageTypeEnum).map((method) => ({
                      name: formantPackageType(method),
                      value: method,
                    }))}
                    onSelectOption={(opt) => setPackageType(opt)}
                  />
                </div>
              </div>
              <div
                className="flex flex-1 justify-end"
                style={{ maxWidth: '50rem' }}
              >
                <PrimaryButton
                  type="submit"
                  className="w-full sm:w-32 h-9"
                  disabled={disabled}
                >
                  Guardar
                  <DocumentArrowDownIcon className="ml-2 w-5 h-5"></DocumentArrowDownIcon>
                </PrimaryButton>
              </div>
            </div>

            <div className="flex flex-1   w-full justify-between gap-12 md:gap-12 flex-col-reverse md:flex-row">
              <div className="flex flex-2" style={{ maxWidth: '45rem' }}>
                <FormText
                  id="search"
                  name="search"
                  value={search}
                  placeholder="Busqueda rapida de guía..."
                  containerClassname="flex flex-1"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="flex flex-1 justify-between sm:items-center flex-col-reverse gap-6 sm:gap-2 sm:flex-row">
                <div className="flex items-center text-xs">
                  <span>Mostrar</span>
                  <div className="inline mx-1">
                    <FormSelect
                      id="registersNumber"
                      name="registersNumber"
                      label=""
                      isWidth={true}
                      selected={registersNumber}
                      options={[10, 25, 50, 100]}
                      className="!w-20 !h-8 !px-2 z-50"
                      optionString={(option) => option.toString()}
                      onSelectOption={(option) => setRegistersNumber(option)}
                      style={{ zIndex: 100 }}
                    />
                  </div>
                  <span>registros</span>
                </div>
              </div>
            </div>

            <div className="flex flex-col overflow-y-auto max-h-[600px]">
              <div className="sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div className="">
                    <table className="table-auto snap-start  relative w-full text-gray-600 text-xs text-left border border-gray-300">
                      <thead className="select-none font-semibold border-b border-gray-300 bg-main-500 text-white sticky top-0 z-40">
                        <tr>
                          <th
                            colSpan={2}
                            className="px-4 py-2  sticky left-0 z-20 bg-main-500 text-white"
                          ></th>
                          <th colSpan={3}></th>
                          <th
                            colSpan={3}
                            className="px-4 py-2 border-b border-gray-300 border-l"
                          >
                            <div className="items-center cursor-pointer text-center">
                              <span className="text-center">REMITENTES</span>
                            </div>
                          </th>
                          <th
                            colSpan={6}
                            className="px-4 py-2 border-b border-l border-gray-300"
                          >
                            <div className="items-center cursor-pointer text-center">
                              <span className="text-center">DESTINATARIOS</span>
                            </div>
                          </th>
                          <th
                            colSpan={2}
                            className="border-l border-gray-300"
                          ></th>
                          <th
                            colSpan={7}
                            className="px-4 py-2 border-b border-l border-gray-300"
                          >
                            <div className="items-center cursor-pointer text-center">
                              <span className="text-center">PIEZAS</span>
                            </div>
                          </th>
                        </tr>
                        <tr>
                          <th
                            className="px-4 py-2  sticky left-0 z-20 bg-main-500 text-white"
                            style={{ minWidth: '10rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer ">
                              <span className="text-center">
                                Fecha de creación
                              </span>
                            </div>
                          </th>

                          <th
                            className="px-4 py-2 sticky left-40  z-20 bg-main-500 text-white"
                            style={{ minWidth: '9rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer">
                              <span className="text-center">
                                Numero de Guia / N° de Tracking / Precinto
                              </span>
                            </div>
                          </th>

                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '9rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Tipo de Entrega
                              </span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '15rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Ciudad Destino
                              </span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '15rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Tienda Destino
                              </span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2 border-l border-gray-300"
                            style={{ minWidth: '7rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer">
                              <span className="text-center">
                                Tipo de Documento
                              </span>
                            </div>
                          </th>

                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '4rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Nº de Documento{' '}
                              </span>
                            </div>
                          </th>

                          <th className="px-4 py-2">
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Nombre Remitente
                              </span>
                            </div>
                          </th>

                          <th
                            className="px-4 py-2 border-l border-gray-300"
                            style={{ minWidth: '7rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer">
                              <span className="text-center">
                                Tipo de Documento
                              </span>
                            </div>
                          </th>

                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '5rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Nº de Documento
                              </span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '15rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">Nombre </span>
                            </div>
                          </th>
                          <th className="px-4 py-2">
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Telefono destinatario
                              </span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '12rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Correo destinatario
                              </span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '20rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate ">
                              <span className="text-center">
                                Dirección Destino
                              </span>
                            </div>
                          </th>
                          <th className="px-4 py-2  border-l border-gray-300">
                            <div className="flex gap-2 items-center cursor-pointer truncate border-l border-gray-300">
                              <span className="text-center">Codigo Postal</span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '20rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">Observaciones</span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2 border-l border-gray-300"
                            style={{ minWidth: '8rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Cantidad de Pieza
                              </span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2 "
                            style={{ minWidth: '15rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Categoria Piezas
                              </span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '8rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">Peso en Kg</span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '8rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Dimension Alto
                              </span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '8rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Dimension Ancho
                              </span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '8rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Dimension Largo
                              </span>
                            </div>
                          </th>
                          <th
                            className="px-4 py-2"
                            style={{ minWidth: '8rem' }}
                          >
                            <div className="flex gap-2 items-center cursor-pointer truncate">
                              <span className="text-center">
                                Valor declarado
                              </span>
                            </div>
                          </th>

                          <th />
                        </tr>
                      </thead>
                      <tbody className="z-0">
                        {paginatedShipments.map((importitems, index) => (
                          <ImportElement
                            key={page * registersNumber + index}
                            index={page * registersNumber + index}
                            formik={formik}
                          />
                        ))}

                        {imports.length === 0 && (
                          <tr>
                            <td colSpan={11} className="px-4 py-4 text-center">
                              No hay guías en esta tabla.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <PaginationFooter
              currentPage={page}
              itemsPerPage={registersNumber}
              totalItems={filteredShipments.length}
              onPageChange={setPage}
            />
          </form>
        )}
      </Formik>
      <ModalLoading
        open={loading}
        title={loadingTitle}
        status={loadingStatus}
        setOpen={setLoading}
        onPrint={() => {}}
      />
    </div>
  );
};
